import React from "react"
import styled from "styled-components"

const StyledButton = styled.button`
  background: #67a70c;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-family: inherit;
  font-size: 15px;
  padding: 10px 50px;

  &:focus {
    background: #26610f;
    outline: none;
  }

  &:hover {
    background: #26610f;
  }
`

const Button = ({ label }) => {
  return (
    <StyledButton>
      {label}
    </StyledButton>
  )
}

export default Button
