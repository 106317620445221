import styled from "styled-components"

const Input = styled.input`
  appearance: ${props => props.appearance};
  background: #e7e7eb;
  border: ${props => props.type === "radio" ? "3px solid #e7e7eb" : "none"};
  border-radius: ${props => props.type === "radio" ? "50%" : "0"};
  box-sizing: ${props => props.type !== "radio" && "border-box"};
  font-family: ${props => props.type !== "radio" && "inherit"};
  font-size: ${props => props.type !== "radio" && "15px"};
  height: ${props => props.height};
  padding: ${props => props.type !== "radio" && "10px"};
  vertical-align: ${props => props.verticalAlign};
  width: ${props => props.type === "radio" ? "15px" : "100%"};
  -webkit-appearance: ${props => props.appearance};
  -moz-appearance: ${props => props.appearance};

  &:checked {
    background: #67a70c;
  }

  &:focus {
    outline: none;
  }
`

export default Input
