import React from "react"
import styled from "styled-components"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const Wrapper = styled.div`
  position: relative;
  width: 200px;
`

const StyledSelect = styled.select`
  appearance: none;
  background: #e7e7eb;
  border: none;
  border-radius: 0;
  font-family: inherit;
  font-size: 15px;
  padding: 10px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus {
    outline: none;
  }
`

const Select = ({
  options,
  ...rest
}) => {
  return (
    <Wrapper>
      <StyledSelect {...rest}>
        <option value="">
          選択してください
        </option>
        {options.map(option => {
          return (
            <option
              key={option}
              value={option}
            >
              {option}
            </option>
          )
        })}
      </StyledSelect>
      <ExpandMoreIcon
        style={{
          color: "#7d7d7d",
          pointerEvents: "none",
          position: "absolute",
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)"
        }}
      />
    </Wrapper>
  )
}

export default Select
