import React from "react"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"
// components
import Paragraph from "../atoms/paragraph"

const StyledTelephone = styled.div`
  background: #67a70c;
  border-bottom: 3px solid #ffffff;
  display: grid;
  grid-column-end: -1;
  grid-column-start: 1;
  grid-template-columns: inherit;
  grid-template-rows: inherit;
`

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  grid-column-end: -2;
  grid-column-start: 2;
  justify-content: space-around;
  padding: 50px 0;

  @media (max-width: 959px) {
    flex-direction: column;
    justify-content: stretch;
  }
`

const Telephone = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isTabletOrMobile = useMediaQuery({ maxWidth: 959 })
  return (
    <StyledTelephone>
      <Wrapper>
        <h2
          style={{
            color: "#ffffff",
            fontSize: isMobile ? "large" : "x-large",
            lineHeight: 1.5,
            margin: 0,
            marginBottom: isTabletOrMobile && "10px"
          }}
        >
          <strong style={{ background: "linear-gradient(transparent 70%, #ffd900 70%, #ffd900 100%)" }}>
            お電話でもお問い合わせいただけます。
          </strong>
        </h2>
        <div>
          <h3
            style={{
              color: "#ffffff",
              fontSize: isMobile ? "x-large" : "xx-large",
              lineHeight: 1.5,
              margin: 0,
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: isMobile ? "large" : "x-large",
                marginRight: "5px"
              }}
            >
              tel.
            </span>
            03-5380-0065
          </h3>
          <Paragraph
            border="1px solid #ffffff"
            color="#ffffff"
            fontSize="15px"
            padding="10px"
            paragraph="【受付時間】9:00〜18:00（日曜日を除く）"
          />
        </div>
      </Wrapper>
    </StyledTelephone>
  )
}

export default Telephone
