import React from "react"
import styled from "styled-components"
// modules
import useTable from "../../modules/use_table"
// components
import Table from "../molecules/table"
import Button from "../atoms/button"

const StyledForm = styled.div`
  grid-column-end: -2;
  grid-column-start: 2;
  padding: ${props => props.padding};
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Form = ({
  form,
  name,
  ...rest
}) => {
  const table = useTable(form)
  return (
    <StyledForm {...rest}>
      <p
        style={{
          color: "#ff0000",
          fontSize: "small",
          lineHeight: 1.5,
          margin: 0,
          marginBottom: "5px"
        }}
      >
        <span
          style={{
            fontSize: "15px",
            marginRight: "5px"
          }}
        >
          *
        </span>
        必須
      </p>
      <form
        name={name}
        method="POST"
        data-netlify="true"
        action="/success"
      >
        <input
          type="hidden"
          name="form-name"
          value={name}
        />
        <Table
          marginBottom="10px"
          table={table}
        />
        <Wrapper>
          <Button
            type="submit"
            label="送信する"
          />
        </Wrapper>
      </form>
    </StyledForm>
  )
}

export default Form
