import styled from "styled-components"

const Textarea = styled.textarea.attrs({ rows: 5 })`
  background: #e7e7eb;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 15px;
  padding: 10px;
  resize: none;
  width: 100%;

  &:focus {
    outline: none;
  }
`

export default Textarea
