import React from "react"
import { getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
// components
import Layout from "../../components/layout"
import TitleImage from "../../components/molecules/title_image"
import Form from "../../components/organisms/form"
import Telephone from "../../components/organisms/telephone"
import SEO from "../../components/seo"

const Contact = ({
  location,
  data
}) => {
  const form = [
    {
      tableHeader: {
        id: "name",
        label: "お名前",
        required: true
      },
      tableData: {
        type: "text",
        name: "name",
        id: "name",
        placeholder: "例）中野 太郎",
        required: true
      }
    },
    {
      tableHeader: {
        id: "katakana",
        label: "フリガナ",
        required: true
      },
      tableData: {
        type: "text",
        name: "katakana",
        id: "katakana",
        placeholder: "例）ナカノ タロウ",
        required: true
      }
    },
    {
      tableHeader: {
        id: "email",
        label: "メールアドレス",
        required: true
      },
      tableData: {
        type: "email",
        name: "email",
        id: "email",
        placeholder: "例）marutomo@mercury.dti.ne.jp",
        required: true
      }
    },
    {
      tableHeader: {
        id: "tel",
        label: "電話番号"
      },
      tableData: {
        type: "tel",
        name: "tel",
        id: "tel",
        placeholder: "例）03-5380-0065"
      }
    },
    {
      tableHeader: {
        id: "address",
        label: "住所"
      },
      tableData: {
        type: "text",
        name: "address",
        id: "address",
        placeholder: "例）東京都中野区新井1-11-9 5階"
      }
    },
    {
      tableHeader: {
        label: "ご希望の連絡方法",
        required: true
      },
      tableData: {
        type: "radio",
        name: "ご希望の連絡方法",
        required: true,
        labels: [
          "メール",
          "電話"
        ]
      }
    },
    {
      tableHeader: {
        label: "お問い合わせの種類",
        required: true
      },
      tableData: {
        type: "radio",
        name: "お問い合わせの種類",
        required: true,
        labels: [
          "ご相談",
          "お見積り依頼"
        ]
      }
    },
    {
      tableHeader: {
        id: "message",
        label: "お問い合わせ内容",
        required: true
      },
      tableData: {
        name: "message",
        id: "message",
        required: true
      }
    }
  ]
  return (
    <Layout location={location}>
      <TitleImage
        image={getImage(data.file)}
        alt="" //
        heading="お問い合わせ"
      />
      <Form
        form={form}
        padding="25px 0"
        name="contact"
      />
      <Telephone />
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query {
    file(relativePath: { eq: "contact.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          height: 250,
          layout: FIXED
        )
      }
    }
  }
`

export const Head = ({ location }) => (
  <SEO
    title="お問い合わせ"
    description="株式会社マルトモのお問い合わせページです。"
    pathname={location.pathname}
  />
)
