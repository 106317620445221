import React from "react"
// components
import UnorderedList from "../components/atoms/unordered_list"
import Input from "../components/atoms/input"
import Select from "../components/atoms/select"
import Textarea from "../components/atoms/textarea"

const TableData = ({
  labels,
  ...rest
}) => {
  return (
    <UnorderedList display="flex">
      {labels.map((label, index) => {
        return (
          <li
            key={label}
            style={{ marginRight: index + 1 < labels.length && "5px" }}
          >
            <label>
              <Input
                {...rest}
                value={label}
                appearance="none"
                height="15px"
                verticalAlign="top"
              />
              {label}
            </label>
          </li>
        )
      })}
    </UnorderedList>
  )
}

const components = {
  text: Input,
  email: Input,
  tel: Input,
  radio: TableData
}

const Other = ({
  options,
  ...rest
}) => {
  return options
    ? <Select
        {...rest}
        options={options}
      />
    : <Textarea {...rest} />
}

const TableHeader = ({
  id,
  label,
  required
}) => {
  return (
    <>
      {id
        ? <label htmlFor={id}>
            {label}
          </label>
        : label
      }
      {required &&
        <span
          style={{
            color: "#ff0000",
            marginLeft: "5px"
          }}
        >
          *
        </span>
      }
    </>
  )
}

const useTable = form => {
  const table = form.map(value => {
    const {
      tableData,
      tableHeader
    } = value
    const Component = components[tableData.type] || Other
    return {
      header: <TableHeader {...tableHeader} />,
      data: <Component {...tableData} />
    }
  })
  return table
}

export default useTable
